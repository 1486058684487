body {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #ffffff;
  font-size: 20px;
}
body {
    background-color: black;
  /* background-image: url("../images/grunge-abstract-metal-background.jpg");
  background-size: cover;
  background-position: center; */
  /* background-repeat: no-repeat; */
  /* height: 100vh; 
  margin: 0;
  padding: 0; */
}

.nav-item a {
  color: white;
}

.nav-item a:hover {
  color: red;
}
.navbar-nav .nav-link.active {
  color: red;
}

.navbar-toggler {
  background-color: white;
}
#red {
  color: red;
  font-size: 48px;
  
}
#textindent{
    text-indent: 70px;
    font-size: 28px;
}
#textindent2{
  text-indent: 70px;
  font-size: 20px;
}
nav{
    position: sticky;
    top: 0; /* คุณสามารถปรับ top ตามความต้องการ */
    z-index: 1000; /* ให้ Navbar อยู่บนสุดของเนื้อหาอื่นๆ */
}
#noneline{
  text-decoration: none; 
  color: white;
}
#noneline:hover{
  color: red;
}